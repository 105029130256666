import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material';
import '../../index.css';

const theme = createTheme({
    typography: {
},});

const theme2 = createTheme();

const AnimalInfo = ({title, labels, form, change, uploadFiles}) =>{
    const [val, setVal] = useState({
        name: form.name,
        type: form.type,
        age: form.age || '',
        address: form.address || '',
        diagnosis: form.diagnosis || '',
        diet: form.diet || '',
        medication: form.medication || ''
    });

    const [uploads, setUploads] = useState({
        picture: form.picture || [], 
        bloodtest: form.bloodtest || [],
    });

    const handleInputChange = (e) => {
      const { id, value } = e.target;
      setVal((prevVal) => ({
        ...prevVal,
        [id]: value,
      }));
      change({ ...val, [id]: value }); 
    };

    const handleUploads = (e) => {
        const id = e.target.id;
        const files = Array.from(e.target.files);
        setUploads((prevUpload) => ({
            ...prevUpload,
            [id]:  files
        }));
        uploadFiles({...uploads, [id]: files});
        console.log(uploads.picture)
    };

    const handleChooseFile = () => {
        document.getElementById('bloodtest').click();
    };

    const handleChoosePicture = (e) => {
        document.getElementById('picture').click();
    };
    

    
    return (
    <div className="person-form-box">
        <div className="person-title">
            <h3 className="form-title">{title}</h3>
        </div>
        <div className="person-form">
            <ThemeProvider theme={theme}>
                
                <Paper
                className="custom-paper"
                sx={{
                    '& .MuiTextField-root': {
                        m: 1,
                        width: '39.5vw',
                        [theme2.breakpoints.down('834')]: {
                        width: '79vw',
                        },
                    },
                    borderRadius: 0,
                    border: '1px solid gold',
                }}
                component="form"
                noValidate
                autoComplete="off"
                >
                <div>
                    <TextField
                    required
                    id="name"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.name}</span>}
                    value={val.name}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    id="type"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.type}</span>}
                    value={val.type}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    id="age"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.age}</span>}
                    value={val.age}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    id="address"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.address}</span>}
                    value={val.address}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    multiline
                    id="diagnosis"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.diagnosis}</span>}
                    value={val.diagnosis}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    multiline
                    id="diet"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.diet}</span>}
                    value={val.diet}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                <div>
                    <TextField
                    required
                    multiline
                    id="medication"
                    type="text"
                    label={<span style={{fontWeight: 300, color: 'black'}}>{labels.medication}</span>}
                    value={val.medication}
                    onChange={handleInputChange}
                    variant="standard"
                    />
                </div>
                </Paper>
            </ThemeProvider>
        </div>
        <div className="uploads">
            <div className="upload-button">
                <label htmlFor="bloodtest" className="custom-file-label">
                {labels.bloodtest}
                {uploads.bloodtest.length > 0
                            ? uploads.bloodtest.map((file, index) => (
                                <div className="uploaded-files" key={index}>{file.name}</div>
                            ))
                            : ''}
                </label>
                <input
                    accept="image/*"
                    id="bloodtest"
                    type="file"
                    className="custom-file-input"
                    onChange={handleUploads}
                    multiple
                    style={{ display: 'none' }}
                />
                <button
                    className="custom-file-button"
                    onClick={handleChooseFile}
                >{labels.bloodtestBtn}</button>
            </div>
            <div className="upload-button">
                <label htmlFor="picture" className="custom-file-label">
                    {labels.picture}
                    {uploads.picture.length > 0
                            ? uploads.picture.map((file, index) => (
                                <div className="uploaded-files" key={index}>{file.name}</div>
                            ))
                            : ''}
                </label>
                <input
                    required
                    accept="image/*"
                    id="picture"
                    type="file"
                    className="custom-file-input"
                    onChange={handleUploads}
                    multiple
                    style={{ display: 'none' }}
                />
                <button
                    className="custom-file-button"
                    onClick={handleChoosePicture}
                >{labels.pictureBtn}</button>
            </div>
        </div>
    </div>
    );
};

export default AnimalInfo;
