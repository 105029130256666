const descriptions = {
    home:{
        english: <div> Welcome!
        Laila del Monte is a specialist in the field of energy healing and animal communications.
        Most people turn to Laila Del Monte for help with their pets when they encounter limitations with traditional medicine; it is often the veterinarians themselves who recommend turning to her.<br/><br/>
        By filling out the following form with your details and those of your pet, Laila will be able to carry out an initial screening to determine whether she will be able to start a healing process with your pet.
        Remote care involves 7 sessions over a 3-week period.
        Laila del Monte requests that no other type of energy modalities (such as reiki) be carried out during this period. <br/><br/>
        During energy treatments it is necessary to continue to follow all the treatments prescribed by your veterinarian and consult him for any subsequent changes.</div>,
        spanish: <div>¡Bienvenido!
        Laila del Monte es especialista en el campo de la sanación energética y la comunicación animal.
        La mayoría de la gente recurre a Laila Del Monte en busca de ayuda con sus mascotas cuando encuentran limitaciones con la medicina tradicional; Muchas veces son los propios veterinarios quienes recomiendan recurrir a ella.<br/><br/>
        Al rellenar el siguiente formulario con sus datos y los de su mascota, Laila podrá realizar un screening inicial de su caso para determinar si puede iniciar un proceso de curación con su mascota.
        La sanación energetica consiste en 7 sesiones durante un período de 3 semanas.
        Laila del Monte solicita que no se realice ningún otro tipo de tratamiento energizante (como reiki) durante este periodo.<br/><br/>
        Durante los tratamientos energéticos es necesario seguir todos los tratamientos prescritos por el veterinario y consultarle para posibles cambios posteriores.</div>,
        italian: <div>Benvenuti!<br/>
        Laila del Monte è una specialista nel campo delle guarigioni energetiche e delle comunicazioni con gli animali.
        La maggior parte delle persone si rivolgono a Laila Del Monte per chiedere aiuto con i propri animali quando incontrano dei limiti con la medicina tradizionale; sono spesso gli stessi veterinari a consigliare di rivolgersi a lei.<br/><br/>
        Compilando il seguente modulo con i vostri dati e quelli del vostro animale, Laila avrà modo di fare un primo screening del vostro caso per determinare se potrà avviare un percorso di guarigione con il vostro animale.
        Le cure a distanza prevedono 7 sessioni durante un periodo di 3 settimane. 
        Laila del Monte richiede che durante questo periodo non venga effettuato nessun altro tipo di trattamento energizzante (come il reiki).<br/><br/>
        Durante le cure energetiche è necessario continuare a seguire tutti i trattamenti prescritti dal veterinario e consultare lo stesso per qualsiasi successivo cambiamento.</div>
    }
}

const titles = {
    english: "INITIAL SUMMARY OF YOUR PET’S HEALTH STATUS",
    spanish: "RESUMEN INICIAL DEL ESTADO DE SALUD DE SU MASCOTA",
    italian: "STATO INIZIALE DI SALUTE DELL'ANIMALE"  
}


const titlesDisclaimers = {
    english: "Disclaimer",
    spanish: "Exención responsabilidad civil",
    italian: "Esonero da responsabilità civile"
};

const signatureDisclaimers = {
    english: "I have read this document carefully and I sign it with full knowledge of the facts.",
    spanish: "He leído con atención la presente y la firmo con pleno conocimiento de causa.",
    italian: "Ho letto attentamente questo documento e lo firmo con piena cognizione di causa."
};

const disclaimersHealings = {
    english: "The spiritual healing offered by Laila del Monte is in no way intended to be a diagnosis, cure, healing, or treatment of any disease, condition or illness, in the medical sense of these terms. No part of Laila del Monte's spiritual healing is ever intended to be a substitute for professional medical treatment or advice. If your pet has a current medical condition, or even the possibility of a serious medical condition, or even the possibility of a serious condition developing, you should seek the advice and care of a licensed veterinarian or health care professional immediately. Any reference to 'healing' refers exclusively to personal spiritual healing. If your pet has a medical problem, please consult a qualified health care practitioner immediately. Spiritual healing works at the non physical level, and Laila del Monte cannot diagnose, treat, heal or prescribe medical care for any physical conditions. You are solely responsible for the medical situation, care and health of the physical body of your pet. This service is not intended to diagnose, treat, cure or prevent any disease. Your signature here will confirm that you have read, understood and fully accepted to participate voluntarily in the spiritual healing offered by Laila del Monte and you indemnify her from any recourse whatsoever and whenever from any consequences henceforth.",
    spanish: "Declaro que doña Laila del Monte no será responsable en ningún caso de los daños y perjuicios, cualquiera que sea su naturaleza y alcance, sufridos por cualquiera de los seres vivos  dotados de sensibilidad que se encuentran bajo mi custodia y en relación, ya sea de manera directa o indirecta, con la 'canalización espiritual para los animales' que tendrá lugar el completar este campo en completar este campo. Me comprometo a seguir las instrucciones dadas por doña Laila del Monte durante la 'canalización espiritual para los animales'. Es responsabilidad mía garantizar la seguridad de los seres vivos dotados de sensibilidad que se encuentran bajo mi custodia y tomar todas las precauciones necesarias, en particular las relacionadas con las indicaciones del veterinario, antes y durante la 'canalización espiritual para los animales'. La 'canalización espiritual para los animales' es una ayuda espiritual que se aporta a los seres dotados de sensibilidad, y su propósito no es servir como tratamiento de una dolencia médica de cualquier tipo. Este servicio no está destinado a diagnosticar, tratar, curar o prevenir ninguna enfermedad. Su firma aquí confirmará que ha leído este documento, que lo ha entendido y ha aceptado plena y voluntariamente participar en la canalización espiritual ofrecida por Laila del Monte, y que garantiza eximirla de toda responsabilidad por cualquiera de las eventuales consecuencias que puedan surgir de aquí en adelante.",
    italian: "Dichiaro che la Sig.ra Laila del Monte non sarà in nessun caso responsabile per i danni, qualunque sia la loro natura e portata, subiti da qualcuno degli esseri viventi dotati di sensibilità che sono in mia custodia e in relazione, sia diretta o indiretta, durante la «tele assistenza energetica per gli animali».Mi impegno a seguire le indicazioni impartite dalla Sig.ra Laila del Monte durante la ‘tele assistenza energetica per gli animali’. È mia responsabilità garantire la sicurezza degli esseri viventi sensibili sotto la mia custodia e prendere tutte le precauzioni necessarie, in particolare quelle relative alle istruzioni veterinarie, prima e durante la ‘tele assistenza energetica per gli animali’. ‘Il supporto energetico a distanza per gli animali’ è un aiuto spirituale fornito agli esseri viventi dotati di sensibilità e non è destinato a servire come trattamento per una condizione medica di alcun tipo. L’ ‘assistenza energetica a distanza per gli animali non intende sostituire le cure veterinarie. Attesto che per gli esseri viventi sensibili sotto la mia custodia non esiste alcuna controindicazione veterinaria che impedisca loro di ricevere ‘assistenza energetica a distanza per gli animali’. La sua firma qui confermerà che ha letto questo documento, che lo ha compreso e che ha accettato pienamente e volontariamente di partecipare alla canalizzazione spirituale offerta da Laila del Monte e che garantisce di esonerarla da ogni responsabilità per qualsiasi eventuale conseguenza che potrebbe verificarsi in seguito."
};

const disclaimersCommunication = {
    english: "The spiritual happiness counseling offered by Laila del Monte is in no way intended to be a diagnosis, cure, healing, or treatment of any disease, condition, behavioral condition or illness, in the medical sense of these terms. No part of Laila del Monte's spiritual happiness counseling is ever intended to be a substitute for professional medical treatment or advice. If your pet has a current medical condition, or even the possibility of a serious medical condition, or even the possibility of a serious condition developing, you should seek the advice and care of a licensed veterinarian or health care professional immediately. Any reference to 'happiness counseling' refers exclusively to personal spiritual happiness counseling. You are solely responsible for the medical situation, care and health of the physical body of your pet. Your signature here will confirm that you have read, understood and fully accepted to participate voluntarily in the spiritual happiness counseling offered by Laila del Monte and you indemnify her from any recourse whatsoever and whenever from any consequences henceforth.",
    spanish: "Declaro que doña Laila del Monte no será responsable en ningún caso de los daños y perjuicios, cualquiera que sea su naturaleza y alcance, sufridos por cualquiera de los seres vivos  dotados de sensibilidad que se encuentran bajo mi custodia y en relación, ya sea de manera directa o indirecta, con la 'canalización espiritual con los animales' que tendrá lugar el completar este campo en completar este campo.Me comprometo a seguir las instrucciones dadas por doña Laila del Monte durante la 'canalización espiritual con los animales'. Es responsabilidad mía garantizar la seguridad de los seres vivos dotados de sensibilidad que se encuentran bajo mi custodia y tomar todas las precauciones necesarias, en particular las relacionadas con las indicaciones del veterinario, antes y durante la 'canalización espiritual con los animales'.La 'canalización espiritual con los animales' es una ayuda espiritual que se aporta a los seres dotados de sensibilidad, y su propósito no es servir como tratamiento de una dolencia médica de cualquier tipo. Este servicio no está destinado a diagnosticar, tratar, curar o prevenir ninguna enfermedad. Su firma aquí confirmará que ha leído este documento, que lo ha entendido y ha aceptado plena y voluntariamente participar en la canalización espiritual ofrecida por Laila del Monte, y que garantiza eximirla de toda responsabilidad por cualquiera de las eventuales consecuencias que puedan surgir de aquí en adelante.",
    italian: "Dichiaro che la Sig.ra Laila del Monte non sarà in nessun caso responsabile per i danni, qualunque sia la loro natura e portata, subiti da qualcuno degli esseri viventi dotati di sensibilità che sono in mia custodia e in relazione, sia diretta o indiretta, durante la comunicazione con gli animali. Mi impegno a seguire le indicazioni impartite dalla Sig.ra Laila del Monte durante la comunicazione animale. È mia responsabilità garantire la sicurezza degli esseri viventi sensibili sotto la mia custodia e prendere tutte le precauzioni necessarie, in particolare quelle relative alle istruzioni veterinarie, prima e durante la comunicazione animale. La sua firma qui confermerà che ha letto questo documento, che lo ha compreso e che ha accettato pienamente e volontariamente di partecipare alla canalizzazione spirituale offerta da Laila del Monte e che garantisce di esonerarla da ogni responsabilità per qualsiasi eventuale conseguenza che potrebbe verificarsi in seguito."
};


const disclaimersConnection = {
    english: "I declare that Laila del Monte will in no case be responsible for damages, whatever their nature and extent, suffered by any of the living beings endowed with sensitivity who are in my custody and in relation, whether direct or indirect, during the ‘connection with dead animals'. I undertake to follow the instructions given by Laila del Monte during the ‘connection with dead animals'. Your signature here will confirm that you have read this document, that you understand it and that you have fully and voluntarily agreed to participate in the spiritual channeling offered by Laila del Monte and that you guarantee to exempt her from any responsibility for any possible consequences that may occur in following.",
    spanish: "Declaro que doña Laila del Monte no será responsable en ningún caso de los daños y perjuicios, cualquiera que sea su naturaleza y alcance, sufridos por cualquiera de los seres vivos  dotados de sensibilidad que se encuentran bajo mi custodia y en relación, ya sea de manera directa o indirecta, con la «canalización espiritual para mascotas fallecidos» que tendrá lugar el Completar este campo en Completar este campo.La «canalización espiritual para mascotas fallecidos» es una ayuda espiritual que se aporta a las personas que han perdido a su animal. Su firma aquí confirmará que ha leído este documento, que lo ha entendido y ha aceptado plena y voluntariamente participar en la canalización espiritual ofrecida por Laila del Monte y/o su personal designado y autorizado, y que garantiza eximirla de toda responsabilidad por cualquiera de las eventuales consecuencias que puedan surgir de aquí en adelante.",
    italian: "Dichiaro che la Sig.ra Laila del Monte non sarà in nessun caso responsabile per i danni, qualunque sia la loro natura e portata, subiti da qualcuno degli esseri viventi dotati di sensibilità che sono in mia custodia e in relazione, sia diretta o indiretta, durante la ‘tele assistenza energetica per gli animali morti’.Mi impegno a seguire le indicazioni impartite dalla Sig.ra Laila del Monte durante la ‘tele assistenza energetica per gli animali morti’. La sua firma qui confermerà che ha letto questo documento, che lo ha compreso e che ha accettato pienamente e volontariamente di partecipare alla canalizzazione spirituale offerta da Laila del Monte e che garantisce di esonerarla da ogni responsabilità per qualsiasi eventuale conseguenza che potrebbe verificarsi in seguito."
};


const introCommunication = {
    english: "Animal Communication sessions mainly allow you to understand the behavior of an animal, whether emotional, behavioral, territorial, etc. Laila del Monte will answer the questions asked in this form. She will first establish a connection with your pet for 30 minutes before speaking to you, using the photo you have uploaded. We thank you for respecting the 30 minutes allotted for this appointment.",
    spanish: "Las sesiones de Comunicación Animal permiten principalmente comprender el comportamiento de un animal, ya sea emocional, conductual, territorial, etc. Laila del Monte responderá a las preguntas formuladas en este formulario. Primero establecerá una conexión con su mascota durante 30 minutos antes de hablar con usted, utilizando la foto que ha subido.Le agradecemos que respete los 30 minutos asignados para esta cita.",
    italian: "Le sessioni di comunicazione animale ci permettono principalmente di comprendere il comportamento di un animale, sia esso emotivo, comportamentale, territoriale, ecc. Laila del Monte risponderà alle domande poste in questo modulo. Stabilirà innanzitutto una connessione con il suo animale domestico per 30 minuti prima di parlare con lei, utilizzando la foto che ha caricato su questo modulo.La ringraziamo di rispettare i 30 minuti concessi per questo appuntamento."
}

const introConnection = {
    english: "Laila del Monte is honored to make a connection with your deceased pet. She will transmit to you the messages and information which will be transmitted to her to help you in the difficult passage represented by the loss of a companion. With the photo that you will have uploaded when making an appointment, Laila will connect with the spirit of your animal for approximately 30 to 45 minutes before speaking to you. We ask you to please respect the 30 minutes of your session.",
    spanish: "Laila del Monte se siente honrada de establecer una conexión con su mascota en el más allá. Ella le transmitirá los mensajes y las informaciones que le serán transmitidas para ayudarle en el difícil paso que representa la pérdida de un compañero. Con la foto que habrá subido al concertar la cita, Laila conectará con el espíritu de su animal durante aproximadamente 30 a 45 minutos antes de hablar con usted. Le rogamos de respetar los 30 minutos de su sesión.",
    italian: "Laila del Monte è onorata di stabilire un legame con il suo animale nell'aldilà. Lei le trasmetterà i messaggi e le informazioni che le verranno trasmesse per aiutarla nel difficile passaggio rappresentato dalla perdita di un compagno. Con la foto che caricherà al momento della fissazione dell'appuntamento, Laila si connetterà con lo spirito del suo animale per circa 30-45 minuti prima di parlarle. La preghiamo di rispettare i 30 minuti della vostra seduta."
}

const cancellationPolicyTitle = {
    english: "Cancellation Policy",
    spanish: "Politica de cancelación",
    italian: "Condizioni di cancellazione"
}

const cancellationPolicyHealings = {
    english: "If the animal were to die during the duration of the healing sessions, there will be no refund.",
    spanish: "Si el animal falleciera durante la duración de las sesiones de curación, no habrá reembolso.",
    italian: "Qualora l'animale dovesse morire durante la durata delle sedute di guarigione non sarà previsto alcun rimborso."
}

const cancellationPolicySessions = {
    english: "You will be charged a cancellation fee if you cancel or reschedule your appointment without giving a minimum of 72 hours notice prior to the start of the appointment. The cancellation fee will be 100% of the service amount. ",
    spanish: "Se le cobrará una tarifa si decide cancelar o reprogramar su cita sin avisar con un mínimo de 72 horas de antelación al inicio de su cita. La penalización por cancelación será igual al 100% del importe del servicio.",
    italian: "Le verrà addebitata una penale se decide di annullare o riprogrammare l'appuntamento senza dare un preavviso minimo di 72 ore prima dell'inizio dell'appuntamento. La penale di cancellazione sarà pari al 100% dell’importo del servizio."
}


export {descriptions, titles, titlesDisclaimers, signatureDisclaimers, disclaimersHealings, disclaimersCommunication, disclaimersConnection, introCommunication, introConnection, cancellationPolicyHealings, cancellationPolicyTitle, cancellationPolicySessions};