import react from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const PrivacyPolicy = () => {
    return (
        <div className="container-privacy-policy">
            <Link to={"/"}><div className="logo-container-2"></div></Link>
            <div className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>Last updated: March 11, 2024</p>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.</p>
                <h2>Interpretation and Definitions</h2>
                <h3>Interpretation</h3>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h3>Definitions</h3>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                <li>
                <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                </li>
                <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                </li>
                <li>
                <p><strong>Application</strong> refers to Laila del Monte, the software program provided by the Company.</p>
                </li>
                <li>
                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Jeev-Atma Inc, 12235 Killion street Valley Village, CA 91607.</p>
                </li>
                <li>
                <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                </li>
                <li>
                <p><strong>Country</strong> refers to: California,  United States</p>
                </li>
                <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                </li>
                <li>
                <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                </li>
                <li>
                <p><strong>Service</strong> refers to the Application or the Website or both.</p>
                </li>
                <li>
                <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                </li>
                <li>
                <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                </li>
                <li>
                <p><strong>Website</strong> refers to Laila del Monte, accessible from <a href="lailadelmontescheduling.com" rel="external nofollow noopener" target="_blank">lailadelmontescheduling.com</a></p>
                </li>
                <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                </li>
                </ul>
                <h2>Collecting and Using Your Personal Data</h2>
                <h3>Types of Data Collected</h3>
                <h4>Personal Data</h4>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                <ul>
                <li>
                <p>Email address</p>
                </li>
                <li>
                <p>First name and last name</p>
                </li>
                <li>
                <p>Phone number</p>
                </li>
                <li>
                <p>Address, State, Province, ZIP/Postal code, City</p>
                </li>
                <li>
                <p>Usage Data</p>
                </li>
                </ul>
                <h4>Usage Data</h4>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <h4>Information Collected while Using the Application</h4>
                <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
                <ul>
                <li>Pictures and other information from your Device's camera and photo library</li>
                </ul>
                <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
                <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
                <h4>Tracking Technologies and Cookies</h4>
                <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                <ul>
                <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                </ul>
                <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" target="_blank">Privacy Policies website</a> article.</p>
                <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <ul>
                <li>
                <p><strong>Necessary / Essential Cookies</strong></p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                </li>
                <li>
                <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                </li>
                <li>
                <p><strong>Functionality Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                </li>
                </ul>
                <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
                <h3>Use of Your Personal Data</h3>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                <li>
                <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                </li>
                <li>
                <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                </li>
                <li>
                <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                </li>
                <li>
                <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                </li>
                <li>
                <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                </li>
                <li>
                <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                </li>
                <li>
                <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                </li>
                <li>
                <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                </li>
                </ul>
                <p>We may share Your personal information in the following situations:</p>
                <ul>
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
                <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                </ul>
                <h3>Retention of Your Personal Data</h3>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                <h3>Transfer of Your Personal Data</h3>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                <h3>Delete Your Personal Data</h3>
                <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
                <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
                <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
                <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
                <h3>Disclosure of Your Personal Data</h3>
                <h4>Business Transactions</h4>
                <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <h4>Law enforcement</h4>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h4>Other legal requirements</h4>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
                </ul>
                <h3>Security of Your Personal Data</h3>
                <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <h2>Children's Privacy</h2>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <h2>Links to Other Websites</h2>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h2>Changes to this Privacy Policy</h2>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                <ul>
                <li>By email: lailadelmonteinfo@gmail.com</li>
                </ul>
            </div>
            <div className="privacy-policy">
                <h1>Política de privacidad</h1>
                <p>Última actualización: 11 de marzo de 2024</p>
                <p>Esta Política de privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza el Servicio, y le informa sobre sus derechos de privacidad y cómo la ley le protege.</p>
                <p>Utilizamos sus datos personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de la información de acuerdo con esta Política de privacidad. Esta Política de privacidad ha sido creada con la ayuda del <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Generador de políticas de privacidad</a>.</p>
                <h2>Interpretación y definiciones</h2>
                <h3>Interpretación</h3>
                <p>Las palabras cuya inicial está en mayúscula tienen significados definidos en las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.</p>
                <h3>Definiciones</h3>
                <p>A los efectos de esta Política de privacidad:</p>
                <ul>
                <li>
                    <p><strong>Cuenta</strong> significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Afiliado</strong> significa una entidad que controla, es controlada por o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, intereses patrimoniales u otros valores con derecho a voto para la elección de directores u otra autoridad de gestión.</p>
                </li>
                <li>
                    <p><strong>Aplicación</strong> se refiere a Laila del Monte, el programa de software proporcionado por la Empresa.</p>
                </li>
                <li>
                    <p><strong>Empresa</strong> (referida como "la Empresa", "Nosotros", "Nuestro" o "Nuestros" en este Acuerdo) se refiere a Jeev-Atma Inc, 12235 Killion Street, Valley Village, CA 91607.</p>
                </li>
                <li>
                    <p><strong>Cookies</strong> son pequeños archivos que se colocan en su computadora, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contiene detalles de su historial de navegación en ese sitio web, entre sus muchos usos.</p>
                </li>
                <li>
                    <p><strong>País</strong> se refiere a: California, Estados Unidos.</p>
                </li>
                <li>
                    <p><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</p>
                </li>
                <li>
                    <p><strong>Datos personales</strong> son cualquier información que se relacione con una persona identificada o identificable.</p>
                </li>
                <li>
                    <p><strong>Servicio</strong> se refiere a la Aplicación o al Sitio web o ambos.</p>
                </li>
                <li>
                    <p><strong>Proveedor de servicios</strong> significa cualquier persona natural o jurídica que procesa los datos en nombre de la Empresa. Se refiere a empresas o individuos de terceros empleados por la Empresa para facilitar el Servicio, para proporcionar el Servicio en nombre de la Empresa, para realizar servicios relacionados con el Servicio o para ayudar a la Empresa a analizar cómo se utiliza el Servicio.</p>
                </li>
                <li>
                    <p><strong>Datos de uso</strong> se refiere a datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).</p>
                </li>
                <li>
                    <p><strong>Sitio web</strong> se refiere a Laila del Monte, accesible desde <a href="lailadelmontescheduling.com" rel="external nofollow noopener" target="_blank">lailadelmontescheduling.com</a></p>
                </li>
                <li>
                    <p><strong>Usted</strong> significa la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.</p>
                </li>
                </ul>
                <h2>Recopilación y uso de sus datos personales</h2>
                <h3>Tipos de datos recopilados</h3>
                <h4>Datos personales</h4>
                <p>Mientras utiliza nuestro Servicio, es posible que le solicitemos que nos proporcione cierta información personalmente identificable que se pueda utilizar para ponerse en contacto o identificarlo. La información personalmente identificable puede incluir, entre otras cosas:</p>
                <ul>
                <li>
                    <p>Dirección de correo electrónico</p>
                </li>
                <li>
                    <p>Nombre y apellido</p>
                </li>
                <li>
                    <p>Número de teléfono</p>
                </li>
                <li>
                    <p>Dirección, estado, provincia, código postal, ciudad</p>
                </li>
                <li>
                    <p>Datos de uso</p>
                </li>
                </ul>
                <h4>Datos de uso</h4>
                <p>Los datos de uso se recopilan automáticamente al utilizar el Servicio.</p>
                <p>Los datos de uso pueden incluir información como la dirección de protocolo de Internet de su dispositivo (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la fecha y hora de su visita, el tiempo que pasa en esas páginas, identificadores de dispositivos únicos y otros datos diagnósticos.</p>
                <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar automáticamente cierta información, incluidos, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivos únicos y otros datos diagnósticos.</p>
                <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>
                <h4>Información recopilada mientras utiliza la aplicación</h4>
                <p>Mientras utiliza nuestra aplicación, y con su permiso previo para proporcionar funciones de nuestra aplicación, podemos recopilar:</p>
                <ul>
                <li>Fotos y otra información de la cámara y la biblioteca de fotos de su dispositivo</li>
                </ul>
                <p>Utilizamos esta información para proporcionar funciones de nuestro Servicio, mejorar y personalizar nuestro Servicio. La información puede ser cargada en los servidores de la empresa y/o en los servidores de un proveedor de servicios, o simplemente almacenarse en su dispositivo.</p>
                <p>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento a través de la configuración de su dispositivo.</p>
                <h4>Tecnologías de seguimiento y cookies</h4>
                <p>Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas pueden incluir balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar nuestro Servicio. Las tecnologías que utilizamos pueden incluir:</p>
                <ul>
                <li><strong>Cookies o cookies del navegador.</strong> Una cookie es un pequeño archivo colocado en su dispositivo. Puede indicar a su navegador que rechace todas las cookies o que le avise cuando se envía una cookie. Sin embargo, si no acepta cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro Servicio puede utilizar cookies.</li>
                <li><strong>Web Beacons.</strong> Ciertas secciones de nuestro Servicio y nuestros correos electrónicos pueden contener pequeños archivos electrónicos conocidos como web beacons (también denominados gifs claros, etiquetas de píxeles y gifs de un solo píxel) que permiten a la Empresa, por ejemplo, contar usuarios que han visitado esas páginas o abierto un correo electrónico y obtener otras estadísticas relacionadas con el sitio web (por ejemplo, registrar la popularidad de una cierta sección y verificar la integridad del sistema y del servidor).</li>
                </ul>
                <p>Las cookies pueden ser "persistentes" o "de sesión". Las cookies persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan pronto como cierra su navegador web. Obtenga más información sobre las cookies en el <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" target="_blank">sitio web de Políticas de privacidad</a>.</p>
                <p>Utilizamos tanto cookies de sesión como persistentes con los siguientes fines:</p>
                <ul>
                <li>
                    <p><strong>Cookies necesarias / esenciales</strong></p>
                    <p>Tipo: Cookies de sesión</p>
                    <p>Administradas por: Nosotros</p>
                    <p>Propósito: Estas cookies son esenciales para proporcionarle servicios disponibles a través del Sitio web y para permitirle utilizar algunas de sus funciones. Ayudan a autenticar a los usuarios y a prevenir el uso fraudulento de las cuentas de usuario. Sin estas cookies, no se pueden proporcionar los servicios que ha solicitado, y solo utilizamos estas cookies para proporcionarle esos servicios.</p>
                </li>
                <li>
                    <p><strong>Cookies de aceptación de política / aviso</strong></p>
                    <p>Tipo: Cookies persistentes</p>
                    <p>Administradas por: Nosotros</p>
                    <p>Propósito: Estas cookies identifican si los usuarios han aceptado el uso de cookies en el Sitio web.</p>
                </li>
                <li>
                    <p><strong>Cookies de funcionalidad</strong></p>
                    <p>Tipo: Cookies persistentes</p>
                    <p>Administradas por: Nosotros</p>
                    <p>Propósito: Estas cookies nos permiten recordar las elecciones que realiza cuando utiliza el Sitio web, como recordar sus detalles de inicio de sesión o preferencias de idioma. El propósito de estas cookies es proporcionarle una experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez que utilice el Sitio web.</p>
                </li>
                </ul>
                <p>Para obtener más información sobre las cookies que utilizamos y sus opciones con respecto a las cookies, visite nuestra Política de cookies o la sección de cookies de nuestra Política de privacidad.</p>
                <h3>Uso de sus datos personales</h3>
                <p>La Empresa puede utilizar datos personales para los siguientes fines:</p>
                <ul>
                <li>
                    <p><strong>Para proporcionar y mantener nuestro Servicio</strong>, incluido el monitoreo del uso de nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Para gestionar su cuenta:</strong> para gestionar su registro como usuario del Servicio. Los datos personales que proporciona pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.</p>
                </li>
                <li>
                    <p><strong>Para el cumplimiento de un contrato:</strong> el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con nosotros a través del Servicio.</p>
                </li>
                <li>
                    <p><strong>Para ponerse en contacto con usted:</strong> para ponerse en contacto con usted por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones push de aplicaciones móviles sobre actualizaciones o comunicaciones informativas relacionadas con las funciones, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</p>
                </li>
                <li>
                    <p><strong>Para proporcionarle</strong> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha comprado o sobre los que ha preguntado, a menos que haya optado por no recibir dicha información.</p>
                </li>
                <li>
                    <p><strong>Para gestionar sus solicitudes:</strong> para atender y gestionar sus solicitudes hacia nosotros.</p>
                </li>
                <li>
                    <p><strong>Para transferencias comerciales:</strong> podemos utilizar su información para evaluar o realizar una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de la   totalidad o parte de nuestros activos, ya sea como empresa en funcionamiento o como parte de un proceso de quiebra, liquidación o procedimiento similar, en el que los datos personales que tenemos sobre los usuarios de nuestro Servicio sean uno de los activos transferidos.</p>
                </li>
                <li>
                    <p><strong>Para otros fines</strong>: podemos utilizar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</p>
                </li>
                </ul>
                <p>Podemos compartir su información personal en las siguientes situaciones:</p>
                <ul>
                <li><strong>Con proveedores de servicios:</strong> podemos compartir su información personal con proveedores de servicios para monitorear y analizar el uso de nuestro Servicio y para ponerse en contacto con usted.</li>
                <li><strong>Para transferencias comerciales:</strong> podemos compartir o transferir su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la empresa, financiamiento o adquisición de la totalidad o parte de nuestro negocio por otra empresa.</li>
                <li><strong>Con afiliados:</strong> podemos compartir su información con nuestras filiales, en cuyo caso requeriremos que esas filiales cumplan con esta Política de privacidad. Las filiales incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlemos o que estén bajo el control común con nosotros.</li>
                <li><strong>Con socios comerciales:</strong> podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</li>
                <li><strong>Con otros usuarios:</strong> cuando comparte información personal o interactúa en áreas públicas con otros usuarios, esa información puede ser vista por todos los usuarios y puede distribuirse públicamente fuera.</li>
                <li><strong>Con su consentimiento</strong>: podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
                </ul>
                <h3>Retención de sus datos personales</h3>
                <p>La Empresa retendrá sus datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Retendremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
                <p>La Empresa también retendrá los Datos de uso con fines de análisis interno. Los Datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>
                <h3>Transferencia de sus datos personales</h3>
                <p>Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Empresa y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a —y mantenida en— computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden ser diferentes a las de su jurisdicción.</p>
                <p>Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.</p>
                <p>La Empresa tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de manera segura y de acuerdo con esta Política de privacidad, y ninguna transferencia de sus Datos personales tendrá lugar a una organización o a un país a menos que existan controles adecuados, incluida la seguridad de sus datos y otra información personal.</p>
                <h3>Eliminar sus datos personales</h3>
                <p>Tiene el derecho de eliminar o solicitar que ayudemos a eliminar los Datos personales que hemos recopilado sobre usted.</p>
                <p>Nuestro Servicio puede brindarle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.</p>
                <p>Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su cuenta, si la tiene, y visitando la sección de configuración de la cuenta que le permite administrar su información personal. También puede ponerse en contacto con nosotros para solicitar acceso, corrección o eliminación de cualquier información personal que nos haya proporcionado.</p>
                <p>Lea teniendo en cuenta que es posible que necesitemos retener cierta información cuando tengamos una obligación legal o base legal para hacerlo.</p>
                <h3>Divulgación de sus datos personales</h3>
                <h4>Transacciones comerciales</h4>
                <p>Si la Empresa participa en una fusión, adquisición o venta de activos, sus Datos personales pueden ser transferidos. Proporcionaremos aviso antes de que sus Datos personales sean transferidos y estén sujetos a una Política de privacidad diferente.</p>
                <h4>Aplicación de la ley</h4>
                <p>Bajo ciertas circunstancias, la Empresa puede estar obligada a divulgar sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</p>
                <h4>Otros requisitos legales</h4>
                <p>La Empresa puede divulgar sus Datos personales de buena fe cuando considere que dicha acción es necesaria para:</p>
                <ul>
                <li>Cumplir con una obligación legal</li>
                <li>Proteger y defender los derechos o bienes de la Empresa</li>
                <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
                <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
                <li>Protegerse contra la responsabilidad legal</li>
                </ul>
                <h3>Seguridad de sus Datos personales</h3>
                <p>La seguridad de sus Datos personales es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Aunque nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta.</p>
                <h2>Privacidad de los niños</h2>
                <p>Nuestro Servicio no se dirige a personas menores de 13 años. No recopilamos intencionadamente información de identificación personal de niños menores de 13 años. Si usted es padre, madre o tutor y sabe que su hijo nos ha proporcionado datos personales, contáctenos. Si nos damos cuenta de que hemos recopilado datos personales de niños menores de 13 años sin verificación del consentimiento parental, tomaremos medidas para eliminar esa información de nuestros servidores.</p>
                <p>Si necesitamos depender del consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recopilar y utilizar esa información.</p>
                <h2>Enlaces a otros sitios web</h2>
                <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</p>
                <p>No tenemos control sobre y no asumimos responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros.</p>
                <h2>Cambios a esta Política de privacidad</h2>
                <p>Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.</p>
                <p>Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio antes de que el cambio surta efecto y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad.</p>
                <p>Le recomendamos que revise esta Política de privacidad periódicamente para ver si hay cambios. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</p>
                <h2>Contáctenos</h2>
                <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</p>
                <ul>
                    <li>Por correo electrónico: lailadelmonteinfo@gmail.com</li>
                </ul>
            </div>
            <div>
                <h1>Informativa sulla privacy</h1>
                <p>Ultimo aggiornamento: 11 marzo 2024</p>
                <p>Questa Informativa sulla privacy descrive le nostre politiche e procedure sulla raccolta, l'uso e la divulgazione delle tue informazioni quando utilizzi il Servizio e ti informa sui tuoi diritti alla privacy e su come la legge ti protegge.</p>
                <p>Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio. Utilizzando il Servizio, accetti la raccolta e l'uso delle informazioni in conformità con questa Informativa sulla privacy. Questa Informativa sulla privacy è stata creata con l'aiuto del <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Generatore di Informativa sulla privacy</a>.</p>
                <h2>Interpretazione e definizioni</h2>
                <h3>Interpretazione</h3>
                <p>Le parole la cui lettera iniziale è maiuscola hanno significati definiti nelle seguenti condizioni. Le seguenti definizioni avranno lo stesso significato indipendentemente che compaiano al singolare o al plurale.</p>
                <h3>Definizioni</h3>
                <p>Per gli scopi di questa Informativa sulla privacy:</p>
                <ul>
                <li>
                    <p><strong>Account</strong> significa un account unico creato per te per accedere al nostro Servizio o a parti del nostro Servizio.</p>
                </li>
                <li>
                    <p><strong>Affiliato</strong> significa un'entità che controlla, è controllata o è sotto il controllo comune di una parte, dove "controllo" significa proprietà del 50% o più delle azioni, interessi patrimoniali o altri titoli aventi diritto di voto per l'elezione di direttori o di altre autorità gestionali.</p>
                </li>
                <li>
                    <p><strong>Applicazione</strong> si riferisce a Laila del Monte, il programma software fornito dalla Società.</p>
                </li>
                <li>
                    <p><strong>Azienda</strong> (indicata come "l'Azienda", "Noi", "Us" o "Our" in questo Accordo) si riferisce a Jeev-Atma Inc, 12235 Killion street Valley Village, CA 91607.</p>
                </li>
                <li>
                    <p><strong>Cookies</strong> sono piccoli file che vengono posizionati sul tuo computer, dispositivo mobile o su qualsiasi altro dispositivo da un sito web, contenenti i dettagli della tua cronologia di navigazione su quel sito tra i suoi molteplici utilizzi.</p>
                </li>
                <li>
                    <p><strong>Paese</strong> si riferisce a: California, Stati Uniti</p>
                </li>
                <li>
                    <p><strong>Dispositivo</strong> significa qualsiasi dispositivo che può accedere al Servizio come un computer, un cellulare o un tablet digitale.</p>
                </li>
                <li>
                    <p><strong>Dati personali</strong> si riferisce a qualsiasi informazione che riguarda un individuo identificato o identificabile.</p>
                </li>
                <li>
                    <p><strong>Servizio</strong> si riferisce all'Applicazione o al Sito web o a entrambi.</p>
                </li>
                <li>
                    <p><strong>Prestatore di servizi</strong> significa qualsiasi persona fisica o giuridica che elabora i dati per conto della Società. Si riferisce a società o individui di terze parti impiegati dalla Società per facilitare il Servizio, per fornire il Servizio per conto della Società, per eseguire servizi correlati al Servizio o per assistere la Società nell'analisi di come viene utilizzato il Servizio.</p>
                </li>
                <li>
                    <p><strong>Dati di utilizzo</strong> si riferisce ai dati raccolti automaticamente, generati dall'uso del Servizio o dall'infrastruttura del Servizio stessa (ad esempio, la durata di una visita a una pagina).</p>
                </li>
                <li>
                    <p><strong>Sito web</strong> si riferisce a Laila del Monte, accessibile da <a href="lailadelmontescheduling.com" rel="external nofollow noopener" target="_blank">lailadelmontescheduling.com</a></p>
                </li>
                <li>
                    <p><strong>Tu</strong> significa l'individuo che accede o utilizza il Servizio, o l'azienda o un'altra entità giuridica per conto della quale tale individuo accede o utilizza il Servizio, se applicabile.</p>
                </li>
                </ul>
                <h2>Raccolta e utilizzo dei tuoi dati personali</h2>
                <h3>Tipi di dati raccolti</h3>
                <h4>Dati personali</h4>
                <p>Mentre utilizzi il nostro Servizio, potremmo chiederti di fornirci alcune informazioni personali identificabili che possono essere utilizzate per contattarti o identificarti. Le informazioni personali identificabili possono includere, ma non sono limitate a:</p>
                <ul>
                <li>
                    <p>Indirizzo email</p>
                </li>
                <li>
                    <p>Nome e cognome</p>
                </li>
                <li>
                    <p>Numero di telefono</p>
                </li>
                <li>
                    <p>Indirizzo, Stato, Provincia, CAP, Città</p>
                </li>
                <li>
                    <p>Dati di utilizzo</p>
                </li>
                </ul>
                <h4>Dati di utilizzo</h4>
                <p>I dati di utilizzo vengono raccolti automaticamente durante l'utilizzo del Servizio.</p>
                <p>I dati di utilizzo possono includere informazioni come l'indirizzo Protocollo Internet del tuo dispositivo (ad esempio, l'indirizzo IP), il tipo di browser, la versione del browser, le pagine del nostro Servizio che visiti, l'ora e la data della visita, il tempo trascorso su quelle pagine, identificatori univoci del dispositivo e altri dati diagnostici.</p>
                <p>Quando accedi al Servizio tramite un dispositivo mobile, potremmo raccogliere automaticamente alcune informazioni, tra cui, ma non solo, il tipo di dispositivo mobile che utilizzi, l'ID univoco del tuo dispositivo mobile, l'indirizzo IP del tuo dispositivo mobile, il sistema operativo mobile, il tipo di browser Internet mobile che utilizzi, identificatori univoci del dispositivo e altri dati diagnostici.</p>
                <p>Potremmo anche raccogliere informazioni che il tuo browser invia ogni volta che visiti il nostro Servizio o quando accedi al Servizio tramite un dispositivo mobile.</p>
                <h4>Informazioni raccolte durante l'utilizzo dell'Applicazione</h4>
                <p>Mentre utilizzi la nostra Applicazione, al fine di fornire funzionalità della nostra Applicazione, potremmo raccogliere, previa tua autorizzazione:</p>
                <ul>
                <li>Immagini e altre informazioni dalla fotocamera e dalla libreria fotografica del tuo dispositivo</li>
                </ul>
                <p>Utilizziamo queste informazioni per fornire funzionalità del nostro Servizio, per migliorare e personalizzare il nostro Servizio. Le informazioni possono essere caricate sui server dell'azienda e/o su server di un fornitore di servizi o possono essere semplicemente archiviate sul tuo dispositivo.</p>
                <p>Puoi abilitare o disabilitare l'accesso a queste informazioni in qualsiasi momento tramite le impostazioni del tuo dispositivo.</p>
                <h4>Tecnologie di tracciamento e cookies</h4>
                <p>Utilizziamo cookies e tecnologie di tracciamento simili per monitorare l'attività sul nostro Servizio e memorizzare alcune informazioni. Le tecnologie di tracciamento utilizzate sono beacon, tag e script per raccogliere e monitorare informazioni e per migliorare e analizzare il nostro Servizio. Le tecnologie che utilizziamo possono includere:</p>
                <ul>
                <li><strong>Cookies o Cookie del browser.</strong> Un cookie è un piccolo file posizionato sul tuo dispositivo. Puoi istruire il tuo browser a rifiutare tutti i cookie o a indicarti quando un cookie viene inviato. Tuttavia, se non accetti i cookie, potresti non essere in grado di utilizzare alcune parti del nostro Servizio. A meno che tu non abbia regolato le impostazioni del tuo browser in modo che rifiuti i cookie, il nostro Servizio potrebbe utilizzare i cookie.</li>
                <li><strong>Web Beacon.</strong> Alcune sezioni del nostro Servizio e delle nostre email possono contenere piccoli file elettronici noti come web beacon (anche chiamati gif trasparenti, tag pixel e gif a singolo pixel) che consentono all'azienda, ad esempio, di contare gli utenti che hanno visitato quelle pagine o aperto un'email e per altre statistiche correlate al sito web (ad esempio, registrazione della popolarità di una determinata sezione e verifica dell'integrità del sistema e del server).</li>
                </ul>
                <p>I cookie possono essere "Persistenti" o "Di sessione". I cookie persistenti rimangono sul tuo computer personale o sul tuo dispositivo mobile quando vai offline, mentre i cookie di sessione vengono eliminati non appena chiudi il tuo browser web. Scopri di più sui cookie nell'articolo del sito web <a href="https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking" target="_blank">Privacy Policies</a>.</p>
                <p>Utilizziamo sia cookie di sessione che cookie persistenti per gli scopi di seguito indicati:</p>
                <ul>
                <li>
                    <p><strong>Cookies Necessari / Essenziali</strong></p>
                    <p>Tipo: Cookie di sessione</p>
                    <p>Amministrato da: Noi</p>
                    <p>Scopo: Questi cookie sono essenziali per fornirti servizi disponibili tramite il Sito web e per consentirti di utilizzare alcune delle sue funzionalità. Aiutano ad autenticare gli utenti e a prevenire l'uso fraudolento degli account utente. Senza questi cookie, i servizi richiesti non possono essere forniti, e utilizziamo questi cookie solo per fornirti tali servizi.</p>
                </li>
                <li>
                    <p><strong>Cookies Politica / Accettazione avviso Cookies</strong></p>
                    <p>Tipo: Cookie persistente</p>
                    <p>Amministrato da: Noi</p>
                    <p>Scopo: Questi cookie identificano se gli utenti hanno accettato l'uso dei cookie sul Sito web.</p>
                </li>
                <li>
                    <p><strong>Cookies Funzionalità</strong></p>
                    <p>Tipo: Cookie persistente</p>
                    <p>Amministrato da: Noi</p>
                    <p>Scopo: Questi cookie ci consentono di ricordare le scelte che fai quando utilizzi il Sito web, come ad esempio ricordare i dettagli del tuo login o le preferenze linguistiche. Lo scopo di questi cookie è fornirti un'esperienza più personale e evitare che tu debba reinserire le tue preferenze ogni volta che utilizzi il Sito web.</p>
                </li>
                </ul>
                <p>Per ulteriori informazioni sui cookie che utilizziamo e sulle tue scelte in merito ai cookie, ti preghiamo di visitare la nostra Cookies Policy o la sezione dei cookie della nostra Informativa sulla privacy.</p>
                <h3>Utilizzo dei tuoi dati personali</h3>
                <p>La Società può utilizzare i dati personali per i seguenti scopi:</p>
                <ul>
                    <li>
                        <p><strong>Per fornire e mantenere il nostro Servizio</strong>, incluso il monitoraggio dell'utilizzo del nostro Servizio.</p>
                    </li>
                    <li>
                        <p><strong>Per gestire il tuo Account:</strong> per gestire la tua registrazione come utente del Servizio. I dati personali che fornisci ti consentono di accedere a diverse funzionalità del Servizio disponibili per te come utente registrato.</p>
                    </li>
                    <li>
                        <p><strong>Per l'esecuzione di un contratto:</strong> lo sviluppo, l'adempimento e l'esecuzione del contratto di acquisto per i prodotti, articoli o servizi che hai acquistato o di qualsiasi altro contratto con noi attraverso il Servizio.</p>
                    </li>
                    <li>
                        <p><strong>Per contattarti:</strong> per contattarti tramite e-mail, chiamate telefoniche, SMS o altre forme equivalenti di comunicazione elettronica, come le notifiche push dell'applicazione mobile riguardanti gli aggiornamenti o comunicazioni informative relative alle funzionalità, prodotti o servizi contrattati, inclusi gli aggiornamenti sulla sicurezza, quando necessario o ragionevole per la loro implementazione.</p>
                    </li>
                    <li>
                        <p><strong>Per fornirti</strong> notizie, offerte speciali e informazioni generali su altri beni, servizi ed eventi che offriamo e che sono simili a quelli che hai già acquistato o su cui hai chiesto informazioni, a meno che tu non abbia optato per non ricevere tali informazioni.</p>
                    </li>
                    <li>
                        <p><strong>Per gestire le tue richieste:</strong> per gestire le tue richieste nei nostri confronti.</p>
                    </li>
                    <li>
                        <p><strong>Per trasferimenti aziendali:</strong> possiamo utilizzare le tue informazioni per valutare o effettuare una fusione, cessione, ristrutturazione, riorganizzazione, liquidazione o altro tipo di vendita o trasferimento di alcuni o di tutti i nostri beni, sia come azienda in attività sia come parte di una procedura di fallimento, liquidazione o procedura simile, in cui i dati personali detenuti da noi riguardanti gli utenti del nostro Servizio sono tra gli asset trasferiti.</p>
                    </li>
                    <li>
                        <p><strong>Per altri scopi</strong>: possiamo utilizzare le tue informazioni per altri scopi, come l'analisi dei dati, l'identificazione delle tendenze di utilizzo, la valutazione dell'efficacia delle nostre campagne promozionali e per valutare e migliorare il nostro Servizio, i prodotti, i servizi, il marketing e la tua esperienza.</p>
                    </li>
                </ul>
                <p>Possiamo condividere le tue informazioni personali nelle seguenti situazioni:</p>
                <ul>
                    <li><strong>Con i fornitori di servizi:</strong> possiamo condividere le tue informazioni personali con fornitori di servizi per monitorare e analizzare l'uso del nostro Servizio, per contattarti.</li>
                    <li><strong>Per trasferimenti aziendali:</strong> possiamo condividere o trasferire le tue informazioni personali in relazione a, o durante le trattative di, qualsiasi fusione, vendita di beni aziendali, finanziamento o acquisizione di tutta o di una parte della nostra attività da parte di un'altra azienda.</li>
                    <li><strong>Con affiliati:</strong> possiamo condividere le tue informazioni con le nostre affiliate, e in tal caso richiederemo a tali affiliate di rispettare questa Informativa sulla privacy. Le affiliate includono la nostra azienda madre e qualsiasi altra società controllata o sotto il controllo comune con noi.</li>
                    <li><strong>Con partner commerciali:</strong> possiamo condividere le tue informazioni con i nostri partner commerciali per offrirti determinati prodotti, servizi o promozioni.</li>
                    <li><strong>Con altri utenti:</strong> quando condividi informazioni personali o interagisci in aree pubbliche con altri utenti, tali informazioni possono essere visualizzate da tutti gli utenti e possono essere distribuite pubblicamente all'esterno.</li>
                    <li><strong>Con il tuo consenso</strong>: possiamo divulgare le tue informazioni personali per qualsiasi altro scopo con il tuo consenso.</li>
                </ul>
                <h3>Conservazione dei tuoi dati personali</h3>
                <p>La Società conserverà i tuoi dati personali solo per il tempo necessario per gli scopi stabiliti in questa Informativa sulla privacy. Conserveremo e utilizzeremo i tuoi dati personali nella misura necessaria per ottemperare ai nostri obblighi legali (ad esempio, se siamo tenuti a conservare i tuoi dati per ottemperare alle leggi applicabili), risolvere controversie ed applicare i nostri accordi e le nostre politiche legali.</p>
                <p>La Società conserverà anche i dati di utilizzo per scopi di analisi interna. Di solito, i dati di utilizzo vengono conservati per un periodo di tempo più breve, a meno che questi dati non vengano utilizzati per rafforzare la sicurezza o migliorare la funzionalità del nostro Servizio, o che siamo legalmente obbligati a conservare questi dati per periodi più lunghi.</p>
                <h3>Trasferimento dei tuoi dati personali</h3>
                <p>Le tue informazioni, incluse i dati personali, sono elaborate negli uffici operativi della Società e in qualsiasi altro luogo in cui si trovano le parti coinvolte nel trattamento. Ciò significa che queste informazioni possono essere trasferite a — e conservate su — computer situati fuori dal tuo stato, provincia, paese o altra giurisdizione governativa in cui le leggi sulla protezione dei dati possono differire da quelle della tua giurisdizione.
                Il tuo consenso a questa Informativa sulla privacy seguito dalla tua presentazione di tali informazioni rappresenta la tua accettazione di tale trasferimento.
                La Società adotterà tutte le misure ragionevolmente necessarie per garantire che i tuoi dati siano trattati in modo sicuro e in conformità con questa Informativa sulla privacy e nessun trasferimento dei tuoi dati personali avrà luogo presso un'organizzazione o un paese a meno che non siano previsti adeguati controlli, compresa la sicurezza dei tuoi dati e di altre informazioni personali.
                </p>
                <h3>Eliminazione dei tuoi dati personali</h3>
                <p>Hai il diritto di eliminare o richiedere che ti aiutiamo nell'eliminazione dei dati personali che abbiamo raccolto su di te.</p>
                <p>Il nostro Servizio potrebbe darti la possibilità di eliminare alcune informazioni su di te all'interno del Servizio.</p>
                <p>Puoi aggiornare, modificare o eliminare le tue informazioni in qualsiasi momento accedendo al tuo Account, se ne hai uno, e visitando la sezione delle impostazioni dell'account che ti consente di gestire le tue informazioni personali. Puoi anche contattarci per richiedere l'accesso, la correzione o l'eliminazione di qualsiasi informazione personale che ci hai fornito.</p>
                <p>Tieni presente, tuttavia, che potremmo dover conservare alcune informazioni quando abbiamo un obbligo legale o una base giuridica per farlo.</p>

                <h3>Divulgazione dei tuoi dati personali</h3>
                <h4>Operazioni aziendali</h4>
                <p>Se la Società è coinvolta in una fusione, acquisizione o vendita di attività, i tuoi dati personali possono essere trasferiti. Forniremo un preavviso prima che i tuoi dati personali vengano trasferiti e diventino soggetti a una diversa Informativa sulla privacy.</p>

                <h4>Applicazione della legge</h4>
                <p>In determinate circostanze, la Società potrebbe essere tenuta a divulgare i tuoi dati personali se richiesto per legge o in risposta a richieste valide da parte di autorità pubbliche (ad esempio, un tribunale o un'agenzia governativa).</p>

                <h4>Altri requisiti legali</h4>
                <p>La Società potrebbe divulgare i tuoi dati personali nella buona fede che tale azione sia necessaria per:</p>
                <ul>
                    <li>Rispettare un obbligo legale</li>
                    <li>Proteggere e difendere i diritti o la proprietà della Società</li>
                    <li>Prevenire o indagare su possibili illeciti in relazione al Servizio</li>
                    <li>Proteggere la sicurezza personale degli utenti del Servizio o del pubblico</li>
                    <li>Proteggere da responsabilità legale</li>
                </ul>

                <h3>Sicurezza dei tuoi dati personali</h3>
                <p>La sicurezza dei tuoi dati personali è importante per noi, ma ricorda che nessun metodo di trasmissione su Internet o di conservazione elettronica è sicuro al 100%. Pur impegnandoci a utilizzare mezzi commercialmente accettabili per proteggere i tuoi dati personali, non possiamo garantirne la sicurezza assoluta.</p>

                <h2>Privacy dei bambini</h2>
                <p>Il nostro Servizio non è rivolto a persone di età inferiore ai 13 anni. Non raccogliamo consapevolmente informazioni personali identificabili da chiunque abbia meno di 13 anni. Se sei un genitore o un tutore e sei a conoscenza che tuo figlio ci ha fornito dati personali, ti preghiamo di contattarci. Se veniamo a conoscenza di aver raccolto dati personali da chiunque abbia meno di 13 anni senza verifica del consenso genitoriale, adotteremo misure per rimuovere tali informazioni dai nostri server.</p>
                <p>Se dobbiamo fare affidamento sul consenso come base giuridica per il trattamento delle tue informazioni e il tuo paese richiede il consenso da parte di un genitore, potremmo richiedere il consenso dei tuoi genitori prima di raccogliere e utilizzare tali informazioni.</p>

                <h2>Collegamenti ad altri siti web</h2>
                <p>Il nostro Servizio può contenere collegamenti ad altri siti web che non sono gestiti da noi. Se fai clic su un collegamento di terze parti, sarai reindirizzato al sito di quella terza parte. Ti consigliamo vivamente di rivedere l'Informativa sulla privacy di ogni sito che visiti.</p>
                <p>Non abbiamo alcun controllo e non assumiamo responsabilità per i contenuti, le politiche sulla privacy o le pratiche di siti o servizi di terze parti.</p>

                <h2>Modifiche a questa Informativa sulla privacy</h2>
                <p>Potremmo aggiornare periodicamente la nostra Informativa sulla privacy. Ti informeremo di eventuali modifiche pubblicando la nuova Informativa sulla privacy su questa pagina.</p>
                <p>Ti avviseremo anche via e-mail e/o con un avviso prominente sul nostro Servizio, prima che le modifiche diventino effettive, e aggiorneremo la data "Ultimo aggiornamento" in alto a questa Informativa sulla privacy.</p>
                <p>Ti consigliamo di rivedere periodicamente questa Informativa sulla privacy per eventuali modifiche. Le modifiche a questa Informativa sulla privacy sono efficaci quando vengono pubblicate su questa pagina.</p>

                <h2>Contattaci</h2>
                <p>Se hai domande su questa Informativa sulla privacy, puoi contattarci:</p>
                <ul>
                    <li>Per e-mail: lailadelmonteinfo@gmail.com</li>
                </ul>
            </div>
            <Link to={"/"}><ArrowBackIcon  style={{marginTop: "50px", fontSize: "2.5rem"}}/></Link>
        </div>
    )
};

export default PrivacyPolicy;

