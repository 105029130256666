import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material';
import '../../index.css';

const theme2 = createTheme();



const InformationConnection = ({labels, form, change, uploadPictures}) =>{
    const [val, setVal] = useState({
        name: form.name || '',
        email: form.email || '',
        date: form.date || '',
        hour: form.hour || '',
        nameanimal: form.nameanimal || '',
        typeanimal: form.typeanimal || '',
        datebirth: form.datebirth || '',
        datedeath: form.datedeath || '',
        deathlocation: form.deathlocation || '',
        deathreason: form.deathreason || '',
        city: form.city || '',
        people: form.people || '',
        phone: form.phone || '',
        questions: form.questions || '',
    });

    const [uploads, setUploads] = useState([]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setVal((prevVal) => ({
          ...prevVal,
          [id]: value,
        }));
        change({ ...val, [id]: value }); 
    };
  
      const handleUploads = (e) => {
          const pics = Array.from(e.target.files);
          setUploads(pics);
          uploadPictures(pics);
    };

    const handleChooseFile = () => {
        document.getElementById('picture').click();
    };
    
    return (
        <div className="person-form-box">
            <div className="person-form">
                <Paper
                    sx={{
                    '& .MuiTextField-root': {
                        m: 1,
                        width: '40vw',
                        [theme2.breakpoints.down('834')]: {
                        width: '81vw',
                        },
                    },
                    borderRadius: 0,
                    border: '1px solid gold',
                    }}
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                        required
                        id="name"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.name}</span>}
                        value={val.name}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="email"
                            type="text"
                            label={<span style={{fontWeight: 300, color: 'black'}}>{labels.email}</span>}
                            variant="standard"
                            value={val.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="date"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.date}</span>}
                        value={val.date}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="hour"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.hour}</span>}
                        value={val.hour}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="nameanimal"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.nameanimal}</span>}
                        value={val.nameanimal}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="typeanimal"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.typeanimal}</span>}
                        value={val.typeanimal}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="datebirth"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.datebirth}</span>}
                        value={val.datebirth}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="datedeath"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.datedeath}</span>}
                        value={val.datedeath}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="deathlocation"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.deathlocation}</span>}
                        value={val.deathlocation}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        multiline
                        id="deathreason"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.deathreason}</span>}
                        value={val.deathreason}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="city"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.city}</span>}
                        value={val.city}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        multiline
                        id="people"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.people}</span>}
                        value={val.people}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="phone"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.phone}</span>}
                        value={val.phone}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    <div>
                        <TextField
                        multiline
                        required
                        id="questions"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.questions}</span>}
                        value={val.questions}
                        onChange={handleInputChange}
                        variant="standard"
                        />
                    </div>
                    </Paper>
            </div>
            <div className="uploads">
                <div className="upload-button">
                    <label htmlFor="picture" className="custom-file-label">
                        {labels.picture}
                        {uploads.length > 0
                            ? uploads.map((file, index) => (
                                <div className="uploaded-files" key={index}>{file.name}</div>
                            ))
                            : ''}
                    </label>
                    <input
                        required
                        accept="image/*"
                        id="picture"
                        type="file"
                        className="custom-file-input"
                        onChange={handleUploads}
                        multiple
                        style={{ display: 'none' }}
                    />
                    <button
                        className="custom-file-button"
                        onClick={handleChooseFile}
                    >{labels.pictureBtn}</button>
                </div>
            </div>
        </div>
    );
    }

export default InformationConnection;
