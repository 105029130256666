import React from 'react';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from './AppBar.js';
import Footer from './Footer.js';
import '../index.css';

const buttonStyle = {
    color: 'gold',
    fontSize: '1.1em',
    fontWeight: '370',
    textTransform: 'Capitalize',
    fontFamily: 'Lato, sans-serif'
}


const HomeLanguages = ({link, title, homeColor, containerLinks, services}) => {
    const [isBoxVisible, setBoxVisibility] = useState(false);

    useEffect(() => {
        const slideInBox = () => {
        setBoxVisibility(true);
        };

        document.addEventListener('DOMContentLoaded', slideInBox);

        return () => {
        document.removeEventListener('DOMContentLoaded', slideInBox);
        };
    }, []);
    return (
        <div className='home-languages-container'>
            <AppBar />
            <div className='home-languages-box'>
                <div id={homeColor}></div>
                <div id={containerLinks} className={`slide-animation ${isBoxVisible ? 'visible' : ''}`}>
                    <div id='links'>
                        <h3 className='home-languages-services'>{services}</h3>
                        <Link to={link.healing} style={{textDecoration: 'none'}}><Button style={buttonStyle} size="large" variant="text">{title.healing}</Button></Link>
                        <Link to={link.communication} style={{textDecoration: 'none'}}><Button style={buttonStyle} size="large" variant="text">{title.communication}</Button></Link>
                        <Link to={link.connection} style={{textDecoration: 'none'}}><Button style={buttonStyle} size="large" variant="text">{title.connection}</Button></Link>
                    </div>        
                </div>
            </div>
            <Footer footer='footer' fontweight='300' fontColor='gold'/>
        </div>
    )
}

export default HomeLanguages;