import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const Footer = ({footer, fontweight, fontColor}) => {
    let title = "Privacy Policy";
    return (
        <div className={footer}>
            <p style={{fontWeight: fontweight, color: fontColor}}>© Laila del Monte 2024</p>
            <Link to={`/privacypolicy`} style={{textDecoration: "none"}}><Button style={{ textTransform: "none", color: "gold", fontSize: "0.8rem", paddingLeft: "30px"}}>{title}</Button></Link>
        </div> 
    )
}

export default Footer;