import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Footer from './Footer';


const buttonStyle = {
    display: {'@media (max-width: 834px)': { margin: '0px'}},
    color: 'gold', 
    fontSize: '1em',
    marginRight: '20px',
    marginLeft: '20px',
    fontWeight: 300,
    fontFamily: 'Lato, sans-serif'
}

const Home = () => {
    return (
        <div id="home">
            <div id="home-container"></div>
            <div id="logo-links-container">
                <div id="logo-container-home"></div>
                <h1 id="home-title">Laila del Monte</h1>
                <div id="home-links">
                    <Link to={`/english`} style={{textDecoration: 'none'}}><Button sx={buttonStyle} size="large" variant="text">English</Button></Link>
                    <Link to={`/español`} style={{textDecoration: 'none'}}><Button sx={buttonStyle} size="large" variant="text">Español</Button></Link>
                    <Link to={`/italiano`} style={{textDecoration: 'none'}}><Button sx={buttonStyle} size="large" variant="text">Italiano</Button></Link>
                </div>
            </div>
            <Footer footer='footer-home' fontweight='300' fontColor='gold'/>
        </div>
    )
}

export default Home;
                  