import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Home from './components/Home.js';
import HomeLanguages from './components/HomeLanguages.js';
import Healings from './components/Healings.js';
import Communication from './components/Communication.js';
import Connection from './components/Connection.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import {descriptions, titles, titlesDisclaimers, signatureDisclaimers, disclaimersHealings, disclaimersCommunication, disclaimersConnection, introCommunication, introConnection, cancellationPolicyHealings, cancellationPolicyTitle, cancellationPolicySessions} from './components/texts.js';



const App = () => {
    return (
        <Router>
            <Outlet/>
            <Routes>  
                <Route path='/' element={<Home/>}></Route>
                <Route path='/english' element={<HomeLanguages link={{healing: 'healings', communication: 'communication', connection:'connection'}} homeColor={'homeEnglish'} containerLinks={'english-links'} services={'Services'} title={{healing: 'Healing', communication: 'Communication', connection: 'Connection'}}/>}></Route>
                <Route path='/english/healings' element={<Healings link={'/english'} submit={"Submit"} titleDisclaimer={titlesDisclaimers.english} signature={signatureDisclaimers.english} cancellationTitle={cancellationPolicyTitle.english} cancellationPolicy={cancellationPolicyHealings.english} disclaimer={disclaimersHealings.english} titleHome={titles.english} msg={'Thank you for submitting the form. We will get back to you shortly'} message={'Please fill in all required fields'} description={descriptions.home.english} titleAnimal='Animal' label={{animalname: 'Name', type: 'My animal is', age: 'Age', address: 'Address', diagnosis: 'Diagnosis', diet: 'Diet', medication: 'Medication/Supplements', bloodtest: 'Bloodtest/X-rays/Scanners ', picture: 'Picture *', bloodtestBtn: 'Choose files', pictureBtn: 'Choose pictures', personname: 'Name', personlastname: 'Lastname', phone: 'Phone', email: 'Email'}} titlePerson='Guardian'/>}></Route>
                <Route path='/english/communication' element={<Communication title={'COMMUNICATION'} link={'/english'} submit={"Submit"} intro={introCommunication.english} cancellationTitle={cancellationPolicyTitle.english} cancellationPolicy={cancellationPolicySessions.english} titleDisclaimer={titlesDisclaimers.english} signature={signatureDisclaimers.english} disclaimer={disclaimersCommunication.english} msg={'We received your form, thank you!'} message={'Please fill in all required fields'} label={{name: 'Name and Lastname', email: 'Email', date: 'Date of appointment', hour: 'Time of appointment', nameanimal: 'Name of the animal', typeanimal: 'My animal is', datebirth: 'Date of birth of the animal', city: 'City/Country', addressanimal: 'Address where animal is located', people: 'People and animal that live with the animal', problem: 'Problems', phone: 'Phone', questions: 'Questions', picture: 'Picture *', pictureBtn: 'Choose Pictures'}}/>}></Route>
                <Route path='/english/connection' element={<Connection title={'CONNECTION'} link={'/english'} submit={"Submit"} intro={introConnection.english} cancellationTitle={cancellationPolicyTitle.english} cancellationPolicy={cancellationPolicySessions.english} titleDisclaimer={titlesDisclaimers.english} signature={signatureDisclaimers.english} disclaimer={disclaimersConnection.english} msg={'We received your form, thank you!'} message={'Please fill in all required fields'} label={{name: 'Name and Lastname', email: 'Email', date: 'Date of appointment', hour: 'Time of appointment', nameanimal: 'Name of the animal', typeanimal: 'My animal is', datebirth: 'Date of birth of the animal', datedeath: 'Date of death', deathlocation: 'Place of death', deathreason: 'Death cause', city: 'City/Country', people: 'People and animal that lived with the animal', phone: 'Phone', questions: 'Questions', picture: 'Picture *', pictureBtn: 'Choose Pictures'}}/>}></Route>
                <Route path='/español' element={<HomeLanguages link={{healing: 'sanación', communication: 'comunicación', connection:'conexión'}} homeColor={'homeSpanish'} containerLinks={'spanish-links'} services={'Servicios'} title={{healing: 'Sanación', communication: 'Comunicación', connection: 'Conexión'}}/>}></Route>
                <Route path='/español/sanación' element={<Healings link={'/español'} submit={"Entregar"} titleDisclaimer={titlesDisclaimers.spanish} signature={signatureDisclaimers.spanish} cancellationTitle={cancellationPolicyTitle.spanish} cancellationPolicy={cancellationPolicyHealings.spanish} disclaimer={disclaimersHealings.spanish} titleHome={titles.spanish} msg={'Recibimos su formulario. Nos pondremos en contacto con usted en breve'} message={'Por favor, rellene todos los campos obligatorios'} description={descriptions.home.spanish} titleAnimal='Animal' label={{animalname: 'Nombre', type: 'Mi animal es', age: 'Edad', address: 'Dirección', diagnosis: 'Diagnóstico', diet: 'Dieta', medication: 'Medicamentos/Suplementos', bloodtest: 'Análisis de sangre/rayos X/escáneres ', picture: 'Foto *', bloodtestBtn: 'Seleccionar archivos', pictureBtn: 'Seleccionar fotos', personname: 'Nombre', personlastname: 'Appellido', phone: 'Teléfono', email: 'Email'}} titlePerson='Guardiano'/>}></Route>
                <Route path='/español/comunicación' element={<Communication title={'COMUNICACIÓN'} link={'/español'} submit={"Entregar"} intro={introCommunication.spanish} cancellationTitle={cancellationPolicyTitle.spanish} cancellationPolicy={cancellationPolicySessions.spanish} titleDisclaimer={titlesDisclaimers.spanish} signature={signatureDisclaimers.spanish} disclaimer={disclaimersCommunication.spanish} msg={'¡Recibimos su formulario, gracias!'} message={'Por favor, rellene todos los campos obligatorios'} label={{name: 'Nombre y Appellido', email: 'Email', date: 'Fecha de la cita', hour: 'Hora de la cita', nameanimal: 'Nombre del animal', typeanimal: 'Mi animal es', datebirth: 'Fecha de nacimiento del animal', city: 'Ciudad/País', addressanimal: 'Dirección donde se encuentra el animal', people: 'Personas y animales que conviven con el animal.', problem: 'Problemas', phone: 'Teléfono', questions: 'Preguntas', picture: 'Foto *', pictureBtn: 'Seleccionar fotos'}}/>}></Route>
                <Route path='/español/conexión' element={<Connection title={'CONEXIÓN'} link={'/español'} submit={"Entregar"} intro={introConnection.spanish} cancellationTitle={cancellationPolicyTitle.spanish} cancellationPolicy={cancellationPolicySessions.spanish} titleDisclaimer={titlesDisclaimers.spanish} signature={signatureDisclaimers.spanish} disclaimer={disclaimersConnection.spanish} msg={'¡Recibimos su formulario, gracias!'} message={'Por favor, rellene todos los campos obligatorios'} label={{name: 'Nombre y Appellido', email: 'Email', date: 'Fecha de la cita', hour: 'Hora de la cita', nameanimal: 'Nombre del animal', typeanimal: 'Mi animal es', datebirth: 'Fecha de nacimiento del animal', datedeath: 'Fecha de muerte del animal', deathlocation: 'Lugar de muerte del animal', deathreason: 'Causa de la muerte', city: 'Ciudad/País', people: 'Personas y animales que convivieron con el animal.', phone: 'Teléfono', questions: 'Preguntas', picture: 'Foto *', pictureBtn: 'Seleccionar fotos'}}/>}></Route>
                <Route path='/italiano' element={<HomeLanguages link={{healing: 'cureenergetiche', communication: 'comunicazione', connection:'connessione'}} homeColor={'homeItalian'} containerLinks={'italian-links'} services={'Servizi'} title={{healing: 'Cure energetiche', communication: 'Comunicazione', connection: 'Connessione'}}/>}></Route>  
                <Route path='/italiano/cureenergetiche' element={<Healings link={'/italiano'} submit={"Invia"} titleDisclaimer={titlesDisclaimers.italian} signature={signatureDisclaimers.italian} cancellationTitle={cancellationPolicyTitle.italian} cancellationPolicy={cancellationPolicyHealings.italian} disclaimer={disclaimersHealings.italian} titleHome={titles.italian} msg={'Abbiamo ricevuto il formulario. Vi risponderemo al più presto.'} message={'Si prega di compilare tutti i campi obbligatori'} description={descriptions.home.italian} titleAnimal='Animale' label={{animalname: 'Nome', type: 'Il mio animale è', age: 'Età', address: 'Indirizzo', diagnosis: 'Diagnosi', diet: 'Dieta', medication: 'Medicine/Supplementi', bloodtest: 'Esami del sangue/raggi-x ', picture: 'Foto *', bloodtestBtn: 'Scegliere i file', pictureBtn: 'Scegliere le foto', personname: 'Nome', personlastname: 'Cognome', phone: 'Telefono', email: 'Email'}} titlePerson='Guardiano'/>}></Route>
                <Route path='/italiano/comunicazione' element={<Communication title={'COMUNICAZIONE'} submit={"Invia"} link={'/italiano'} intro={introCommunication.italian} cancellationTitle={cancellationPolicyTitle.italian} cancellationPolicy={cancellationPolicySessions.italian} titleDisclaimer={titlesDisclaimers.italian} signature={signatureDisclaimers.italian} disclaimer={disclaimersCommunication.italian} msg={'Abbiamo ricevuto il suo formulario, grazie!'} message={'Si prega di compilare tutti i campi obbligatori'} label={{name: 'Nome e Cognome', email: 'Email', date: "Data dell'appuntamento", hour: "Ora dell'appuntamento", nameanimal: "Nome dell'animale", typeanimal: 'Il mio animale è', datebirth: "Data di nascita dell'animale", city: 'Cittá/Paese', addressanimal: "Indirizzo di dove si trova l'animale", people: "Persone e animali che vivono con l'animale", problem: 'Problemi', phone: 'Telefono', questions: 'Domande', picture: 'Foto *', pictureBtn: 'Scegliere le foto'}}/>}></Route>
                <Route path='/italiano/connessione' element={<Connection title={'CONNESSIONE'} submit={"Invia"} link={'/italiano'} intro={introConnection.italian} cancellationTitle={cancellationPolicyTitle.italian} cancellationPolicy={cancellationPolicySessions.italian} titleDisclaimer={titlesDisclaimers.italian} signature={signatureDisclaimers.italian} disclaimer={disclaimersConnection.italian} msg={'Abbiamo ricevuto il suo formulario, grazie!'} message={'Si prega di compilare tutti i campi obbligatori'} label={{name: 'Nome e Cognome', email: 'Email', date: "Data dell'appuntamento", hour: "Ora dell'appuntamento", nameanimal: "Nome dell'animale", typeanimal: 'Il mio animale è', datebirth: "Data di nascita dell'animale", datedeath: "Data di morte dell'animale", deathlocation: "Luogo di morte dell'animale", deathreason: 'Cause della morte', city: 'Cittá/Paese', people: "Persone e animali che vivevano con l'animale", problem: 'Problemi', phone: 'Telefono', questions: 'Domande', picture: 'Foto *', pictureBtn: 'Scegliere le foto'}}/>}></Route>  
                <Route path='/privacypolicy' element={<PrivacyPolicy/>}></Route>
            </Routes>
        </Router>
)
}

export default App;