import React, {useState} from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import { Alert,Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonInfo from './HealingsComponents/PersonInfo.js';
import AnimalInfo from './HealingsComponents/AnimalInfo.js';
import Footer from './Footer.js';


const buttonStyle = {
  display: "flex",
  margin: '15px 0 100px 0',
  backgroundColor: '#a1a1a1',
  color: 'white',
  fontWeight: '300',
  borderRadius: '1.5px',
  width: '10ch',
  height: '4ch',
  cursor: 'pointer'
};

const cardStyle = {
  display: "flex",
  width: "41vw",
  padding: "20px",
  backgroundColor: 'white',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  color: 'black'
};

const cardStyle2 = {
  display: {'@media (max-width: 834px)': { width: "79.5vw"}},
  display: "flex",
  width: "41vw",
  padding: "20px",
  backgroundColor: 'white',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  borderBottom: 'none',
  color: 'black'
};

const cardStyle3 = {
  display: {'@media (max-width: 834px)': { width: "79.5vw"}},
  display: "flex",
  width: "41vw",
  padding: "20px",
  backgroundColor: 'white',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  borderTop: 'none',
  color: 'black'
};


let guardian = {
  name: "",
  lastname: "",
  number: "",
  email: ""
};

let animal = {
  name: "",
  age: "",
  address: "",
  diagnosis: "",
  diet: "",
  medication: "",
  bloodtest: "",
  picture: ""
}

let upload = {
  picture: [], 
  bloodtest: []
}

const Healings = (({link, titleHome, description, cancellationPolicy, cancellationTitle, signature, titleDisclaimer, disclaimer, titleAnimal, label, titlePerson, message, msg, submit}) => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [formSent, setFormSent] = useState(false);
  const [formSentMessage, setFormSentMessage] = useState('');
  const [guardianForm, setGuardianForm] = useState(guardian);
  const [animalForm, setAnimalForm] = useState(animal);
  const [uploads, setUploads] = useState(upload);
  const [checkedBox, setCheckedBox] = useState(false);


  const handleGuardianFormChange = (updatedForm) => {
    setGuardianForm(updatedForm)
  };

  const handleAnimalFormChange = (updatedForm) => {
    setAnimalForm(updatedForm)
  };


  const handleUploads = (newPic) => {
    setUploads(newPic)
  };

  const closeAlert = () => {
    setAlert(false);  
  };

  const checkBox = (e) => {
    setCheckedBox(e.target.checked);
  };

  const sendForm = async () => {
    if (
      !guardianForm.name ||
      !guardianForm.lastname || 
      !guardianForm.number || 
      !guardianForm.email || 
      !animalForm.name ||
      !animalForm.type ||
      !animalForm.age ||
      !animalForm.address ||
      !animalForm.diagnosis ||
      !animalForm.diet ||
      !animalForm.medication ||
      uploads.picture.length === 0 ||
      !checkedBox
    )
    {
      if (formSent) {
          setFormSent(false)
      }
      setAlert(true);
      setAlertSeverity('error');
      setAlertMessage(message);
      setTimeout(() => {
          setAlert(false);
      }, 3000)
      return;
    }
    try {
      const data = new FormData();
      data.append('guardianInfo', JSON.stringify({
        name: guardianForm.name,
        lastname: guardianForm.lastname,
        number: guardianForm.number,
        email: guardianForm.email,
      }));
  
      data.append('animalInfo', JSON.stringify({
        name: animalForm.name,
        type: animalForm.type,
        age: animalForm.age,
        address: animalForm.address,
        diagnosis: animalForm.diagnosis,
        diet: animalForm.diet,
        medication: animalForm.medication,
      }));

      uploads.picture.forEach((img, index) => {
        data.append(`picture`, img);
      });

      if (uploads.bloodtest.length > 0) {
        uploads.bloodtest.forEach((img, index) => {
          data.append(`bloodtest`, img);
        });
      }
 
      const headers = {
        'Content-type': 'multipart/form-data'
      }
      const res = await axios.post('/uploadHealingForm', data, {headers})
      console.log(res.data);
      if (alert) {
          setAlert(false)
      }
      setFormSent(true);
      setAlertSeverity('success');
      setFormSentMessage(msg);
      setTimeout(() => {
          setFormSent(false);
      }, 3000)
      return;
      
    } catch (err) {
      console.error('Error: ', err);
    }  
  };
    
  

  return (
    <div id="health-form">
      <Link to={link}><div className="logo-container-2"></div></Link>
      <h1 id="healings-title">{titleHome}</h1>
      {formSent ? <Alert variant="filled" severity={alertSeverity} style={{position: 'fixed', top: '0'}} onClose={closeAlert}><Typography variant="body2">{formSentMessage}</Typography></Alert> : <></> }
            {alert ? <Alert variant="filled" severity={alertSeverity} style={{position: 'fixed', top: '0'}} onClose={closeAlert}><Typography variant="body2">{alertMessage}</Typography></Alert> : <></> }
      <Card sx={{ ...cardStyle, '@media (max-width: 834px)': { width: '85%' } }}> 
          <CardContent>
            {description}
          </CardContent>
      </Card>
      <PersonInfo change={handleGuardianFormChange} form={guardianForm} title={titlePerson} labels={{name: label.personname, lastname: label.personlastname, phone: label.phone, email: label.email}}/>
      <AnimalInfo  change={handleAnimalFormChange} uploadFiles={handleUploads} form={animalForm} title={titleAnimal} labels={{name: label.animalname, type: label.type, age: label.age, address: label.address, diagnosis: label.diagnosis, diet: label.diet, medication: label.medication, bloodtest: label.bloodtest, picture: label.picture, bloodtestBtn: label.bloodtestBtn, pictureBtn: label.pictureBtn, }} />
      <Card sx={{ ...cardStyle2, '@media (max-width: 834px)': { width: '85%' } }}>
          <CardContent>
            <h2 className='title-disclaimer'>{cancellationTitle}</h2>
            {cancellationPolicy}
          </CardContent>
      </Card>
      <Card sx={{ ...cardStyle3, '@media (max-width: 834px)': { width: '85%' } }}>
          <CardContent>
            <h2 className='title-disclaimer'>{titleDisclaimer}</h2>
            {disclaimer}
            <FormGroup className="signature-box">
              <FormControlLabel required onChange={checkBox} checked={checkedBox} control={<Checkbox />} label={<span>{signature}</span>} />
            </FormGroup>
          </CardContent>
      </Card>
      <Button style={buttonStyle} size="large" variant="contained" onClick={sendForm}>{submit}</Button>
      <Footer footer='footer-pages' fontColor='gold' fontweight='300'/>
    </div>
  );
});

export default Healings;