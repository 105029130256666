import React, {useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Alert,Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InformationConnection from './ConnectionComponents/informationConnection';
import Footer from './Footer.js';

const buttonStyle = {
    display: "flex",
    margin: '15px 0 100px 0',
    backgroundColor: '#a1a1a1',
    color: 'white',
    fontWeight: '300',
    borderRadius: '1.5px'
};


const cardStyle = {
  display: "flex",
  width: "41.5vw",
  backgroundColor: 'white',
  marginBottom: '30px',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  color: 'black'
};

const cardStyle2 = {
  display: "flex",
  width: "41vw",
  backgroundColor: 'white',
  marginTop: '20px',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  borderBottom: 'none',
  color: 'black'
};


const cardStyle3 = {
  display: "flex",
  width: "41vw",
  backgroundColor: 'white',
  fontWeight: '300',
  borderRadius: 0,
  border: '1px solid gold',
  borderTop: 'none',
  color: 'black'
};


let initialForm = {
    name: '',
    email: '',
    date: '',
    hour: '',
    nameanimal: '',
    typeanimal: '',
    datebirth: '',
    datedeath: '',
    deathlocation: '',
    deathreason: '',
    city: '',
    people: '',
    phone: '',
    questions: '',
};

const Connection = ({title, link, label, message, msg, cancellationTitle, cancellationPolicy, titleDisclaimer, disclaimer, signature, intro, submit}) => {
    const [form, setForm] = useState(initialForm);
    const [picture, setPicture] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [formSent, setFormSent] = useState(false);
    const [formSentMessage, setFormSentMessage] = useState('');
    const [checkedBox, setCheckedBox] = useState(false);
    
    const handleFormChange = (updatedForm) => {
        setForm(updatedForm)
    };

    const handleUploads = (newPic) => {
        setPicture(newPic)
    };

    const closeAlert = (e) => {
        console.log(e)
        if (alertSeverity === 'error'){
            setAlert(false);
        } else if (alertSeverity === 'success'){
            setFormSent(false);
        }   
    };

    const checkBox = (e) => {
      setCheckedBox(e.target.checked);
    };
  

    const sendForm = async () => {
        if (
          !form.name ||
          !form.email ||
          !form.date || 
          !form.hour || 
          !form.nameanimal || 
          !form.typeanimal ||
          !form.datebirth ||
          !form.datedeath ||
          !form.deathlocation ||
          !form.deathreason ||
          !form.city ||
          !form.people ||
          !form.phone ||
          !form.questions ||
          picture.length === 0 ||
          !checkedBox
        )
        {
          if (formSent) {
              setFormSent(false)
          }
          setAlert(true);
          setAlertSeverity('error');
          setAlertMessage(message);
          setTimeout(() => {
              setAlert(false);
          }, 3000)
          return;
        }
        try {
            const data = new FormData();
            data.append('form', JSON.stringify({
              name: form.name,
              email: form.email,
              date: form.date,
              hour: form.hour,
              nameanimal: form.nameanimal,
              typeanimal: form.typeanimal,
              datebirth: form.datebirth,
              datedeath: form.datedeath,
              deathlocation: form.deathlocation,
              deathreason: form.deathreason,
              city: form.city,
              people: form.people,
              phone: form.phone,
              questions: form.questions
            }));
      
            picture.forEach((img, index) => {
              data.append(`picture`, img);
            });
      
            const headers = {
              'Content-type': 'multipart/form-data'
            }
            const res = await axios.post('/uploadConnectionForm', data, {headers})
            console.log(res.data);
            if (alert) {
              setAlert(false)
            }
            setFormSent(true);
            setAlertSeverity('success');
            setFormSentMessage(msg);
            setTimeout(() => {
                setFormSent(false);
            }, 3000)
            return;
            
          } catch (err) {
            console.error('Error: ', err);
          }
    };

  
    return (
        <div id="connection-form">
            <Link to={link}><div className="logo-container-2"></div></Link>
            <h1 className="main-title">{title}</h1>
            {formSent ? <Alert variant="filled" severity={alertSeverity} style={{position: 'fixed', top: '0'}} onClose={closeAlert}><Typography variant="body2">{formSentMessage}</Typography></Alert> : <></> }
            {alert ? <Alert variant="filled" severity={alertSeverity} style={{position: 'fixed', top: '0'}} onClose={closeAlert}><Typography variant="body2">{alertMessage}</Typography></Alert> : <></> }
            <Card sx={{ ...cardStyle, '@media (max-width: 834px)': { width: '85%' } }}> 
                <CardContent>
                    {intro}
                </CardContent>
            </Card>
            <InformationConnection form={form} change={handleFormChange} uploadPictures={handleUploads} labels={{name: label.name, email: label.email, date: label.date, hour: label.hour, nameanimal: label.nameanimal, typeanimal: label.typeanimal, datebirth: label.datebirth, datedeath: label.datedeath, deathlocation: label.deathlocation, deathreason: label.deathreason, city: label.city, people: label.people, problem: label.problem, phone: label.phone, questions: label.questions, picture: label.picture, pictureBtn: label.pictureBtn}}/>
            <Card sx={{ ...cardStyle2, '@media (max-width: 834px)': { width: '85%' } }}>
                <CardContent>
                    <h2 className='title-disclaimer'>{cancellationTitle}</h2>
                    {cancellationPolicy}
                </CardContent>
            </Card>
            <Card sx={{ ...cardStyle3, '@media (max-width: 834px)': { width: '85%' } }}> 
                <CardContent>
                    <h2 className="title-disclaimer">{titleDisclaimer}</h2>
                    {disclaimer}
                    <FormGroup className="signature-box">
                    <FormControlLabel required onChange={checkBox} checked={checkedBox} control={<Checkbox />} label={<span>{signature}</span>} />
                    </FormGroup>
                </CardContent>
            </Card>
            <Button style={buttonStyle} size="large" variant="contained" onClick={sendForm}>{submit}</Button>
            <Footer footer='footer-pages' fontColor='gold' fontweight='300'/>
        </div>
    )
}

export default Connection;