import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material';
import '../../index.css';

const theme2 = createTheme();
 

const PersonInfo = ({title, labels, form, change}) =>{
    const [val, setVal] = useState({
        name: form.name || '',
        lastname: form.lastname || '',
        number: form.number || '',
        email: form.email || '',
      });
    
    const handleInputChange = (e) => {
      const { id, value } = e.target;
      setVal((prevVal) => ({
        ...prevVal,
        [id]: value,
      }));
      change({ ...val, [id]: value }); 
    };


    return (
        <div className="person-form-box">
            <div className="person-title">
                <h3 className="form-title">{title}</h3>
            </div>
            <div className="person-form">
                    <Paper
                    sx={{
                        '& .MuiTextField-root': {
                            m: 1,
                            width: '19.2vw',
                            [theme2.breakpoints.down('834')]: {
                            width: '38.4vw',
                            },
                        },
                        borderRadius: 0,
                        border: '1px solid gold',
                        }}
                    component="form"
                    noValidate
                    autoComplete="off"
                    >
                    <div>
                        <TextField
                        required
                        id="name"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.name}</span>}
                        variant="standard"
                        value={val.name}
                        onChange={handleInputChange}
                        />
                        <TextField
                        required
                        id="lastname"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.lastname}</span>}
                        variant="standard"
                        value={val.lastname}
                        onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <TextField
                        required
                        id="number"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.phone}</span>}
                        variant="standard"
                        value={val.number}
                        onChange={handleInputChange}
                        />
                        <TextField
                        required
                        id="email"
                        type="text"
                        label={<span style={{fontWeight: 300, color: 'black'}}>{labels.email}</span>}
                        variant="standard"
                        value={val.email}
                        onChange={handleInputChange}
                        />
                    </div>
                    </Paper>
            </div>
        </div>
    );
}
export default PersonInfo;

  