import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { yellow } from '@mui/material/colors';

const drawerWidth = 240;
const navItems = ['English', 'Español', 'Italiano'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ backgroundColor: 'black', height: '100%'}}>
        <Typography variant="h6" sx={{ my: 2 }}></Typography>
        <Divider sx={{color: yellow['A700']}}><Link to='/'><Button><div id="logo-container-3"></div></Button></Link></Divider>
        <List>
          {navItems.map((item, index) => (
              <Link key={index} to={`/${item.toLowerCase()}`} style={{textDecoration: "none"}}>
                <ListItem key={item} disablePadding>     
                      <ListItemButton key={item} sx={{ textAlign: 'center' }}>
                        <ListItemText primary={item.toUpperCase()} sx={{ color: 'gold', fontFamily: 'Lato, sans-serif', fontWeight: '300' }}/> 
                      </ListItemButton>
                </ListItem>
              </Link>
          ))}
        </List>
      </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  

  return (
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar component="nav" sx={{display: {'@media (max-width: 700px)': { width: '100%', background: 'black', height: '8vh'}}, background: 'black',  width: '50%', position: 'absolute', top: 0, right: 0 }}>
          <Toolbar sx={{ height: '50%' }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }}}
            >
            <MenuIcon sx={{color: yellow['A700']}}/>
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item, index) => (
                <Link key={index} to={`/${item.toLowerCase()}`} style={{textDecoration: "none"}}><Button key={item} sx={{ display: {'@media (max-width: 700px)': { paddingTop: '0'}}, color: 'gold', fontWeight: '400', fontSize: '0.8rem', fontFamily: 'Lato, sans-serif' }}>{item}</Button></Link>
              ))}
              <Link to='/'><Button><div id="logo-container"></div></Button></Link>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, 
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, height: '100vh'}
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;